const html = require('choo/html')
const css = require('sheetify')
const homeSection = require('../components/home-section')
const sponsors = require('../elements/home/sponsors')
const callout = require('../components/callout')
const cta = require('../components/cta-btn')
const header = require('../elements/header')
const footer = require('../elements/footer')

module.exports = function (state, emit) {
  emit(state.events.DOMTITLECHANGE, 'Build Haskell fast')

  return html`
    <body>
      ${header()}
    <div>
      <div class="mh2 mh4-l center contain">
        <section class="tc pa3 pt5-ns">
          <h1 class="f2 f1-l mb0 lh-title">
            Build <span class="orange">Haskell</span> fast
          </h1>
          <h2 class="f3 mt3 mb3 color-neutral-50 fw4">
            Use <a href="//bazel.build" class="dim">Google Bazel</a> to distribute and globally cache Haskell builds.
          </h2>

          ${cta(state, emit, {
    href: 'https://docs.bazel.build/versions/master/install.html',
    text: 'Install now',
    id: 'install'
  })}
          ${callout(state, emit)}
        </section>
      </div>
      ${sponsors({klass: 'bg-neutral-04'})}
      ${homeSection({
    'bgColor': 'bg-neutral',
    'title': 'Why use Bazel?',
    'subtitle': `
          When you build software with Bazel, you're running the same code that has been refined and tested for years at Google to build heavy-duty, mission-critical infrastructure, services, and applications.
        `,
    'sections': [
      {
        'title': 'Scalable',
        'text': `
              Distributed build execution and distributed build caches mean
              that builds stay fast even as the code size grows.
              Don't rebuild the same thing twice. Bazel caches based
              on content and caches for everyone.
            `
      },
      {
        'title': 'Correct',
        'text': `
              Sandboxing of build actions guarantees that dependencies
              are declared correctly. This means incremental rebuilds always
              work. Never need to wipe the cache before building.
            `
      },
      {
        'title': 'Polyglot & extensible',
        'text': `
             If you work with 3 languages, don't try composing 3 build
             systems and lose the fight. Use 1 build system that understands
             them all. Add support for new languages yourself.
            `
      }
    ],
    cta: cta(state, emit, {
      id: 'docs-cta',
      href: 'https://rules-haskell.readthedocs.io',
      text: 'Read the documentation',
      klass: 'f5 white bg-animate bg-orange hover-bg-red pa3 link'
    })
  })}
    </div>
      ${footer()}
    </body>
  `
}
