const html = require('choo/html')

module.exports = function (state, emit, props) {
  props = Object.assign({
    klass: 'bg-orange white b f5 no-underline grow dib v-mid ba ph3 pv2'
  }, props)
  var el = html`
    <a id="${props.id}" class="${props.klass}" href="${props.href}">${props.text}</a>
  `

  return el
}
