const html = require('choo/html')

module.exports = function () {
  return html`
  <footer class="bg-neutral white">
    <section class="pv2 ph3 pa4-m mw8-ns center-ns">
      <p class="color-neutral-50 tr f7">
        Site design derived from datproject
      </p>
      <p class="bt b--neutral-80 color-neutral-50 tc f5 pv3">
        Maintained by <strong>Tweag I/O Limited</strong>.
      </p>
    </section>
  </footer>
  `
}
