const html = require('choo/html')
const cta = require('./cta-btn')

module.exports = function (state, emit) {
  return html`
    <div class="mt5-ns mt5 mw8 center justify-center tl flex flex-row-ns flex-column w-100">
      <div class="flex w-100 w-third-ns bg-white box-shadow mv4-ns mv2 mh3-l mh1-ns pa3">
        <div class="flex-column flex">
          <a href="//rules-haskell.readthedocs.io">
            <div class="mt3 w3 h3 center"><img src="/public/img/book.svg"></div>
          </a>
          <h4 class="tc f4 mb0">User guide</h4>
          <p class="tc mb0 f7 ttu dat-neutral-70">
            Learn about Bazel
          </p>
          <ul class="list p0 lh-copy">
            <li class="f5 mb2">
              <b>First steps:</b> getting started with Bazel.
            </li>
            <li class="f5 mb2">
              <b>Polyglot projects:</b> how to integrate many languages using Bazel.
            </li>
            <li class="f5 mb2">
              <b>Advanced use cases:</b> cross-compilation, distributed builds, linting, generating API docs...
            </li>
          </ul>
          <p class="tr mb0" style="margin-top:auto;">
            ${cta(state, emit, {
    href: '//rules-haskell.readthedocs.io',
    text: 'Get Started',
  })}
          </p>
        </div>
      </div>
      <div class="flex w-100 w-third-ns bg-white box-shadow mv4-ns mv2 mh3-l mh1-ns pa3">
        <div class="flex-column flex">
          <a href="//release.api.haskell.build">
            <div class="mt3 w3 h3 center"><img src="/public/img/rules.svg"></div>
          </a>
          <h4 class="f4 mb0 tc">API documentation</h4>
          <p class="tc mb0 f7 ttu dat-neutral-70">
            Reference reading
          </p>
          <ul class="list p0 lh-copy">
            <li class="f5 mb2">
              <b>Rules:</b> available rules and their attributes.
            </li>
            <li class="f5 mb2">
              <b>Macros:</b> convenience functions to generate many targets at once.
            </li>
          </ul>
          <p class="tr mb0" style="margin-top:auto;">
            ${cta(state, emit,
    {
      href: '//release.api.haskell.build',
      text: 'Current release',
    })}
            ${cta(state, emit,
    {
      href: '//master.api.haskell.build',
      text: 'Next release',
    })}
          </p>
        </div>
      </div>
    </div>
  `
}
