const html = require('choo/html')
const css = require('sheetify')

module.exports = function () {
  return html`
    <nav class="bb b--neutral-10 dt w-100 w-100 border-box pa3 ph5-l">
      <div class="dtc v-mid w-25">
        <a class="v-mid dib link dim mr2 w2 h2" href="/" title="rules_haskell">
          <img src="/public/img/logomark.svg">
        </a>
        <a class="v-mid link dim color-neutral b f5 f3-ns dib-l dn" href="/" title="Haskell.build">Haskell.<span class="color-neutral-60">build</span></a>
      </div>

      <div class="dtc v-mid w-75 tr">
        <a class="link dim color-neutral ttu tracked f6 f5-ns dib mr3 mr4-ns" href="https://rules-haskell.readthedocs.io" title="rules_haskell Documentation">Docs</a>
        <a class="link dim color-neutral ttu tracked f6 f5-ns dib mr3 mr4-ns" href="https://github.com/tweag/rules_haskell" title="Fork on GitHub">GitHub</a>
      </div>
    </nav>
  `
}
