const html = require('choo/html')

module.exports = function (props) {
  return html`
    <section class="${props.klass}">
      <div class="pv2 ph3 pa4-m mw8-ns center-ns">
      <header>
        <h2 class="f2 mb0">Trusted by</h2>
      </header>
      <div class="pt3 flex flex-wrap w-100">
        <a class="inline-flex items-center mv2 mh4" href="https://formation.ai" title="Formation.ai">
          <img style="height: 3em" src="/public/img/formation.svg" alt="Formation.ai">
        </a>
        <a class="inline-flex items-center mv2 mh4" href="https://kittyhawk.aero" title="Kitty Hawk">
          <img style="height: 1.5em" src="/public/img/kittyhawk.png" alt="Kitty Hawk">
        </a>
        <a class="inline-flex items-center mv2 mh4" href="https://novadiscovery.com" title="Novadiscovery">
          <img style="height: 3em" src="/public/img/novadiscovery.svg" alt="Novadiscovery">
        </a>
        <a class="inline-flex items-center mv2 mh4" href="https://digitalasset.com" title="Digital Asset">
          <img style="height: 2.4em" src="/public/img/digitalasset.svg" alt="Digital Asset">
        </a>
        <a class="w-100 w-auto-ns inline-flex items-center mv2 mh4" href="https://habito.com/" title="Habito">
          <img class="h-auto w5" src="/public/img/habito.svg" alt="Habito">
        </a>
<!--
        <a class="inline-flex items-center mv2 mh4" href="https://seagate.com" title="Seagate">
          <img class="h3 w-auto" src="/public/img/seagate.svg" alt="Seagate">
        </a>
-->
      </div>
      <div class="pv3">
      </div>
      </div>
    </section>
  `
}
