const choo = require('choo')
const css = require('sheetify')

css('tachyons')
css('dat-colors')
css('./client/css/app.css')
css('./client/css/fonts.css')

const app = choo()
if (process.env.NODE_ENV !== 'production') {
    app.use(require('choo-devtools')())
}

app.route('/', require('./client/pages/home'))
module.exports = app.mount('body')
